import React, { useState, useRef, useEffect } from 'react';
import ScratchCard from 'react-scratchcard-v2';

import scratch from './images/hand-scratch.png';
import playIcon from './images/play.png';
// @ts-ignore
import audio from './loop.wav';
import useSound from 'use-sound';

import './App.scss';

function App() {

  const [play] = useSound(audio, {
    loop: true
  });

  const img = process.env.REACT_APP_IMG;
  const logo = process.env.REACT_APP_LOGO;
  const video = process.env.REACT_APP_VIDEO;

  const [isDragging, setIsDragging] = useState(false);
  const [stage, setStage] = useState(1);
  const [htmlContent, setHtmlContent] = useState('');

  const audioRef = useRef<HTMLAudioElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);

  const isMobile = window.innerWidth < 1280;

  useEffect(() => {
    const fetchHTML = async () => {
      try {
        const response = await fetch(process.env.REACT_APP_URL || '');
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const html = await response.text();
        setHtmlContent(html);
      } catch (error) {
        console.error('Error fetching HTML content:', error);
      }
    };

    fetchHTML();
  }, []);

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.button === 0) {
      setIsDragging(true);
      // if (audioRef.current) {
      //   audioRef.current.play();
      // }
    }
  };

  const handleMouseUp = () => {
    setStage(3)
    setTimeout(() => {
      const el = document.getElementById("video")      
      if (el) {
        // @ts-ignore
        el.play()
      }
      play()
    }, 500);
    // setTimeout(() => {
    //   if (videoRef.current) {
    //     videoRef.current.play()
    //   }
    // }, 500);
    // setIsDragging(false);
    // if (audioRef.current) {
    //   audioRef.current.pause();
    //   audioRef.current.currentTime = 0;
    // }
  };

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    if (isDragging) {
    }
  };

  return (
    <>
      {stage === 4 ? (
        <div className='stage-4-wrap' dangerouslySetInnerHTML={{__html: process.env.REACT_APP_URL || ''}} />
      ) : (
      <div className={`wrapper ${stage === 3 ? 'final' : ''}`}>
        {stage === 1 && (
          <>
            <div className="overlay"></div>
            <div className="how-wrapper">
              <div className="title">{process.env.REACT_APP_TITLE || ''}</div>
              <div className="how-box">
                <img src={scratch} alt="scratch" className='scratch-img' />
              </div>
              <div className="txt">
                {process.env.REACT_APP_TXT || ''}
              </div>
              <div className="play" onClick={() => {
                setStage(2)
              }}>
                <img src={playIcon} alt="play" className='play-img' />
              </div>
            </div>
          </>
        )}
        {stage === 2 && (
          <div 
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onClick={handleMouseUp}
            className="scratch-wrapper">
            <ScratchCard
              width={isMobile ? 320 : 1050}
              height={isMobile ? 200 : 600}
              brushSize={isMobile ? 25 : 40}
              image='https://res.cloudinary.com/dpxjol0kw/image/upload/v1732536271/bg-3_cqctth.png'
              finishPercent={90}
              onComplete={handleMouseUp}
            >
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  height: '100%',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <img src={img} alt="" className='main-img' />
              </div>
            </ScratchCard>
          </div>
        )}
        {stage === 3 && (
          <div className={`video-wrap`}>
            <video preload='auto' muted controls={isMobile} id="video" ref={videoRef} onEnded={() => {
              setStage(4)
            }}>
              <source src={video} type="video/mp4" />
            </video>
          </div>
        )}
        <img src={logo} alt="logo" className='logo-img' />
      </div>
      )}
      {/* <audio ref={audioRef} src="/loop.wav" loop id='audio' /> */}
    </>
  );
}

export default App;
